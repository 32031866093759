import { ChangeEvent, useEffect, useState } from "react";

type TextareaProps = {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
};

function Textarea({ placeholder, value, onChange }: TextareaProps) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  return (
    <textarea
      value={currentValue}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentValue(e.target.value);
      }}
      rows={6}
      placeholder={placeholder}
    ></textarea>
  );
}

export default Textarea;
