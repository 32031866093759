type CheckboxProps = {
  text: string;
  checked: boolean;
  onClick: Function;
  required?: boolean;
};

function Checkbox({ text, checked, onClick, required }: CheckboxProps) {
  return (
    <div className="checkbox">
      <input
        checked={checked}
        onChange={() => onClick(!checked)}
        type="checkbox"
      />
      <span onClick={() => onClick(!checked)}>
        {text}
        {required ? <label className="required"> (obligatoire) *</label> : null}
      </span>
    </div>
  );
}

export default Checkbox;
