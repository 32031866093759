import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { useWakeLock } from "react-screen-wake-lock";

import "./App.css";

import Header from "./components/UI/header/Header";
import Popup from "./components/UI/Popup";
import Menu from "./components/UI/menu/Menu";

import Paragraph from "./components/UI/Paragraph";
import Link from "./components/UI/Link";
import ButtonPicture from "./components/UI/ButtonPicture";
import ButtonPictureMask from "./components/UI/ButtonPictureMask";
import ButtonDocument from "./components/UI/ButtonDocument";
import ButtonInfo from "./components/UI/ButtonInfo";
import Textarea from "./components/UI/Textarea";
import YesNo from "./components/UI/YesNo";
import Checkbox from "./components/UI/Checkbox";
import InputText from "./components/UI/InputText";
import ButtonSignature from "./components/UI/ButtonSignature";
import Loader from "./components/UI/Loader";

import { getFolder, openFolder, updateFolder } from "./requests/folder";

import { DataType, FolderDataType } from "./types/types";

import { convertData } from "./utils/file";

interface ISomeObject {
  [key: string]: DataType;
}

function App() {
  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => {},
    onError: () => {},
    onRelease: () => {},
  });

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  const queryParameters = new URLSearchParams(window.location.search);
  const folderId = queryParameters.get("ID");

  const files: ISomeObject = {
    empty: require("./templates/empty.json"),
    1297: require("./templates/1297.json"), // DEMANDE DE DOCUMENTS MACIF CONSEIL
    1298: require("./templates/1298.json"), // PRE MACIF CONSEIL
    1300: require("./templates/1300.json"), // RELEVE DE DOMMAGES MACIF CONSEIL
    1301: require("./templates/1301.json"), // ROLE EXPERT MACIF CONSEIL
    1305: require("./templates/1305.json"), // EAD SOCIETAIRE MAIF CONSEIL
    1306: require("./templates/1306.json"), // ENVOI DOC CONSEIL
    1308: require("./templates/1308.json"), // PHOTO EXPERTISE CONSEIL
    1765: require("./templates/1765.json"), // ENVOI DOC ALLIANCE
    1766: require("./templates/1766.json"), // EAD SOCIETAIRE MAIF ALLIANCE
    1769: require("./templates/1769.json"), // ROLE EXPERT MACIF ALLIANCE
    1770: require("./templates/1770.json"), // RELEVE DE DOMMAGES MACIF ALLIANCE
    1772: require("./templates/1772.json"), // PRE MACIF ALLIANCE
    1773: require("./templates/1773.json"), // DEMANDE DE DOCUMENTS MACIF ALLIANCE
    1808: require("./templates/1808.json"), // ROLE EXPERT CONSEIL
    1893: require("./templates/1893.json"), // RELEVE DE DOMMAGES MACIF PLURIS CONSEIL
  };

  const [data, setData] = useState<DataType>(files["empty"]);

  const [legalUrl, setLegalUrl] = useState("");
  const [isLoad, setIsLoad] = useState(true);
  const [isLoadUpload, setIsLoadUpload] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isPopup, setIsPopup] = useState(true);
  const [folderData, setFolderData] = useState<FolderDataType>();
  const totalPage = data.body.length;

  const checkEmpty =
    data.body[currentPage] &&
    data.body[currentPage].elements?.filter(
      (element) =>
        !element.value &&
        !element.checked &&
        (element.checked !== false || !element.isCheckFalse) &&
        element.required
    ).length;

  const checkNotEmpty =
    data.body[currentPage] &&
    data.body[currentPage].elements?.filter(
      (element) =>
        (element.value ||
          element.checked ||
          (element.checked === false && element.isCheckFalse)) &&
        element.required
    ).length;

  const checkTotal =
    data.body[currentPage] &&
    data.body[currentPage].elements?.filter((element) => element.required)
      .length;

  useEffect(() => {
    if (folderId) {
      getFolder(folderId)
        .then((result: any) => {
          setIsLoad(false);

          if (result.success) {
            if (result.status < 2) {
              openFolder(folderId)
                .then(() => {})
                .catch(() => {});
            }

            ReactGA.send({
              hitType: "pageview",
              page: `/${result.page}`,
              title: result.page,
            });

            if (files.hasOwnProperty(result.page)) {
              setData(files[result.page]);
            }

            setFolderData({
              reference: result.reference,
              email: result.email,
              page: result.page,
              id: result.id,
              status: result.status,
              variables:
                result.data_sent && result.data_sent.home
                  ? Object.keys(result.data_sent.home).map(
                      (key) => result.data_sent?.home[key]
                    )
                  : [],
            });

            if (result.reference) {
              if (result.reference.includes("groupelang.fr")) {
                setLegalUrl("https://www.groupelang.fr/donnees-personnelles");
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#d73838"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#9a8585"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--text-color",
                  "#451b1b"
                );
              } else if (result.reference.includes("pluris-expertise.com")) {
                setLegalUrl(
                  "https://alliance-experts.com/politique-de-confidentialite"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#71B62B"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#9A8585"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--text-color",
                  "#29430F"
                );
              } else if (result.reference.includes("alliance-experts.com")) {
                setLegalUrl(
                  "https://alliance-experts.com/politique-de-confidentialite"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#0054BF"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#9A8585"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--text-color",
                  "#02145B"
                );
              } else {
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#F96332"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#9A8585"
                );
                // @ts-ignore
                document.documentElement.style.setProperty(
                  "--text-color",
                  "#94462d"
                );
              }
            } else {
              setFolderData(undefined);
            }
          }
        })
        .catch(() => {
          setIsLoad(false);
        });
    }
  }, []);

  if (!folderId) {
    return <Loader show={true} hide={true} text="Référence inconnue" />;
  }

  if (isLoad) {
    return <Loader show={true} text="Chargement du dossier en cours" />;
  }

  if (!folderData || !data.body.length) {
    return <Loader show={true} hide={true} text="Référence inconnue" />;
  }

  if (folderData && folderData.status >= 2) {
    return (
      <Loader
        show={true}
        hide={true}
        text={`Merci, les données ont été transmises au cabinet d'Expertise. Dès lors que nous aurons procédé à nos opérations d’Expertise, nous ne manquerons pas de revenir vers vous afin de vous informer du suivi de votre dossier. Nous restons évidemment à votre écoute pour d’éventuels renseignements complémentaires et nous tenons à votre disposition par mail à l’adresse suivante : ${folderData?.reference}`}
      />
    );
  }

  const _updateFolder = () => {
    setIsLoadUpload(true);
    const serverData = convertData(data);

    ReactGA.event({
      category: "upload",
      action: "upload",
      value: 0,
    });

    if (isSupported) {
      request();
    }

    updateFolder(folderId, serverData)
      .then(() => {
        if (isSupported) {
          release();
        }
        ReactGA.event({
          category: "upload",
          action: "upload_true",
          value: 1,
        });
        setIsLoadUpload(false);
        setFolderData({
          ...folderData,
          status: 2,
        });
      })
      .catch((error) => {
        if (isSupported) {
          release();
        }
        ReactGA.event({
          category: "upload",
          action: "upload_false",
          value: 2,
          label: error,
        });
        setIsLoadUpload(false);
        alert("Une erreur est survenue");
      });
  };

  return (
    <>
      <Header
        title={data.body[currentPage] && data.body[currentPage].title}
        currentPage={currentPage}
        totalPage={totalPage}
        incrementPercentage={
          checkNotEmpty ? (100 / totalPage) * (checkNotEmpty / checkTotal) : 0
        }
        onInfoClick={() => setIsPopup(true)}
        company={data.header.company}
      />
      <Popup
        show={isPopup}
        onClose={() => setIsPopup(false)}
        status={folderData.status}
        texts={data.header.texts}
        company={data.header.company}
        variables={folderData.variables}
        reference={folderData.reference || ""}
        isTime={data.isTime}
      />
      {data.body[currentPage] &&
        data.body[currentPage].elements.map((element, idx) => {
          if (element.type === "Paragraph") {
            return (
              <Paragraph
                key={`${currentPage}-${idx}`}
                reference={folderData.reference}
                text={element.text || ""}
                variables={folderData.variables}
              />
            );
          } else if (element.type === "Link") {
            if (element.url) {
              return (
                <Link
                  key={`${currentPage}-${idx}`}
                  text={element.text || ""}
                  url={element.url}
                />
              );
            }
          } else if (element.type === "LinkLegal") {
            if (legalUrl) {
              return (
                <Link
                  key={`${currentPage}-${idx}`}
                  text={element.text || ""}
                  url={legalUrl}
                />
              );
            }
          } else if (element.type === "ButtonPicture") {
            return (
              <ButtonPicture
                key={`${currentPage}-${idx}`}
                title={element.title || ""}
                value={element.value}
                onChange={(base64) => {
                  let tmpData = { ...data };
                  tmpData.body[currentPage].elements[idx].value = base64;
                  setData(tmpData);
                }}
                required={element.required}
              />
            );
          } else if (element.type === "ButtonPictureMask") {
            return (
              <ButtonPictureMask
                key={`${currentPage}-${idx}`}
                title={element.title || ""}
                value={element.value}
                onChange={(base64) => {
                  let tmpData = { ...data };
                  tmpData.body[currentPage].elements[idx].value = base64;
                  setData(tmpData);
                }}
                required={element.required}
                mask={element.mask || ""}
              />
            );
          } else if (element.type === "ButtonDocument") {
            return (
              <ButtonDocument
                key={`${currentPage}-${idx}`}
                title={element.title || ""}
                valid={element.valid || ""}
                value={element.value}
                onChange={(base64) => {
                  let tmpData = { ...data };
                  tmpData.body[currentPage].elements[idx].value = base64;
                  setData(tmpData);
                }}
                required={element.required}
              />
            );
          } else if (element.type === "ButtonInfo") {
            return (
              <ButtonInfo
                key={`${currentPage}-${idx}`}
                title={element.title || ""}
              />
            );
          } else if (element.type === "Textarea") {
            return (
              <Textarea
                key={`${currentPage}-${idx}`}
                placeholder={element.placeholder || ""}
                value={element.value || ""}
                onChange={(value) => {
                  let tmpData = { ...data };
                  tmpData.body[currentPage].elements[idx].value = value;
                  setData(tmpData);
                }}
              />
            );
          } else if (element.type === "YesNo") {
            return (
              <YesNo
                key={`${currentPage}-${idx}`}
                text={element.text || ""}
                checked={element.checked}
                onClick={(checked) => {
                  let tmpData = { ...data };
                  tmpData.body[currentPage].elements[idx].checked = checked;
                  setData(tmpData);
                }}
                required={element.required}
                defaultFalse={element.defaultFalse}
              />
            );
          } else if (element.type === "Checkbox") {
            return (
              <Checkbox
                key={`${currentPage}-${idx}`}
                checked={element.checked || false}
                onClick={() => {
                  let tmpData = { ...data };
                  tmpData.body[currentPage].elements[idx].checked =
                    !element.checked;
                  setData(tmpData);
                }}
                text={element.text || ""}
                required={element.required}
              />
            );
          } else if (element.type === "InputText") {
            return (
              <InputText
                key={`${currentPage}-${idx}`}
                title={element.title || ""}
                placeholder={element.placeholder || ""}
                value={element.value || ""}
                onChange={(value) => {
                  let tmpData = { ...data };
                  tmpData.body[currentPage].elements[idx].value = value;
                  setData(tmpData);
                }}
                required={element.required}
              />
            );
          } else if (element.type === "ButtonSignature") {
            return (
              <ButtonSignature
                key={`${currentPage}-${idx}`}
                required={element.required}
                value={element.value || ""}
                onChange={(value) => {
                  let tmpData = { ...data };
                  tmpData.body[currentPage].elements[idx].value = value;
                  setData(tmpData);
                }}
              />
            );
          }
        })}

      <Menu
        currentPage={currentPage}
        totalPage={totalPage}
        disabled={!!checkEmpty}
        onPageChange={(page: number) => {
          if (page < 0) {
            setIsPopup(true);
          } else {
            setCurrentPage(page);
          }
        }}
        onEnd={() => {
          _updateFolder();
        }}
      />
      <Loader show={isLoadUpload} text="Envoi en cours !" />
    </>
  );
}

export default App;
