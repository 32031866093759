import "./header.css";

import logoMaif from "../../../images/maif.png";

type HeaderProps = {
  title: string;
  currentPage: number;
  totalPage: number;
  incrementPercentage: number;
  onInfoClick: Function;
  company?: "MAIF";
};

function Header({
  title,
  currentPage,
  totalPage,
  incrementPercentage,
  onInfoClick,
  company,
}: HeaderProps) {
  let currentPercentage = (currentPage * 100) / totalPage + incrementPercentage;

  return (
    <>
      <div className="header">
        {company === "MAIF" ? (
          <img
            style={{ position: "absolute", top: 5, right: 5 }}
            alt="MAIF"
            src={logoMaif}
            height={26}
          />
        ) : (
          <></>
        )}
        <h1>
          {title} - {currentPercentage.toFixed(0)}%
        </h1>
        <div className="progress">
          <div
            style={{
              right: `${100 - currentPercentage}%`,
            }}
          ></div>
        </div>
      </div>
      <div className="header-rectifier"></div>
    </>
  );
}

export default Header;
