import { ChangeEvent, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";

import { getBase64 } from "../../utils/file";

import check from "../../images/check.svg";
import trash from "../../images/trash.svg";
import sync from "../../images/sync.svg";
import pdf from "../../images/pdf.png";

type ButtonDocumentProps = {
  title: string;
  valid: string;
  value: string | undefined;
  onChange: (base64: string) => void;
  required?: boolean;
  isLabelOptionnal?: boolean;
};

function ButtonDocument({
  title,
  valid,
  onChange,
  value,
  required,
  isLabelOptionnal = true,
}: ButtonDocumentProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [currentDocument, setCurrentDocument] = useState<string | null>(
    value || ""
  );

  useEffect(() => {
    onChange(currentDocument || "");
  }, [currentDocument]);

  return (
    <div className="button-picture-container">
      {currentDocument ? (
        <>
          <div onClick={() => setCurrentDocument(null)}>
            <img className="button-picture-icon" alt="trash" src={trash} />
          </div>
          <div className="button-picture-viewer">
            <img
              height={
                currentDocument.includes("image/png") ||
                currentDocument.includes("image/jpg") ||
                currentDocument.includes("image/jpeg")
                  ? ""
                  : "36"
              }
              src={
                currentDocument.includes("image/png") ||
                currentDocument.includes("image/jpg") ||
                currentDocument.includes("image/jpeg")
                  ? currentDocument
                  : pdf
              }
              alt={title}
            />
            <div onClick={() => inputRef.current?.click()}>
              <img
                className="button-picture-icon-right"
                alt="sync"
                src={sync}
              />
            </div>
          </div>
        </>
      ) : null}
      <button
        className={currentDocument ? "button-picture" : "button-document"}
        onClick={() => {
          ReactGA.event({
            category: "click_document",
            action: "open_file",
            value: 0,
          });
          inputRef.current?.click();
        }}
      >
        <span>
          {currentDocument && valid ? valid : title}{" "}
          {required ? "(Obligatoire)" : isLabelOptionnal ? "(Facultatif)" : ""}
        </span>
        {currentDocument ? <img alt="camera" src={check} /> : null}
      </button>
      <input
        style={{ position: "absolute", top: -1000, left: -1000 }}
        ref={inputRef}
        type="file"
        accept="image/*,audio/*,.doc,.csv,.pdf"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            let file = e.target.files[0];

            getBase64(file)
              .then((result: any) => {
                ReactGA.event({
                  category: "click_document",
                  action: "close_file_true",
                  value: 1,
                });
                setCurrentDocument(result.base64);
              })
              .catch(() => {
                ReactGA.event({
                  category: "click_document",
                  action: "close_file_error",
                  value: 3,
                });
              });
          } else {
            ReactGA.event({
              category: "click_document",
              action: "close_file_false",
              value: 2,
            });
          }
        }}
      />
    </div>
  );
}

export default ButtonDocument;
