import { ChangeEvent, useState, useEffect } from "react";

type InputTextProps = {
  title: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
};

function InputText({
  title,
  placeholder,
  value,
  onChange,
  required,
}: InputTextProps) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  return (
    <div className="input-text">
      <span>
        {title}
        {required ? <label className="required"> (obligatoire) *</label> : null}
      </span>
      <input
        value={currentValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setCurrentValue(e.target.value)
        }
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
}

export default InputText;
