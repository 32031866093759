import ReactGA from "react-ga4";

import "./menu.css";

type MenuProps = {
  currentPage: number;
  totalPage: number;
  onPageChange: Function;
  onEnd: Function;
  disabled: boolean;
};

function Menu({
  currentPage,
  totalPage,
  onPageChange,
  onEnd,
  disabled,
}: MenuProps) {
  return (
    <>
      <div style={{ width: "100%", height: "56px", flexShrink: 0 }}></div>
      <div className="menu">
        <button
          style={{ width: "40%" }}
          onClick={() => {
            ReactGA.event({
              category: "click_menu",
              action: "back",
              value: 2,
            });
            onPageChange(currentPage - 1);
          }}
        >
          <span>Retour</span>
        </button>
        <button
          className={disabled ? "menu-button-disabled" : ""}
          onClick={() => {
            if (disabled) {
              alert("Veuillez remplir tous les champs obligatoires");
            } else {
              if (currentPage + 1 === totalPage) {
                ReactGA.event({
                  category: "click_menu",
                  action: "end",
                  value: 4,
                });
                onEnd();
              } else {
                ReactGA.event({
                  category: "click_menu",
                  action: "next",
                  value: 3,
                });
                onPageChange(currentPage + 1);
              }
            }
          }}
        >
          <span>{currentPage + 1 === totalPage ? "Envoyer" : "Suivant"}</span>
        </button>
      </div>
    </>
  );
}

export default Menu;
