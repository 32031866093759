type ParagraphProps = {
  reference: string;
  text: string;
  variables: Array<Array<string>>;
};

function Paragraph({ reference, text, variables }: ParagraphProps) {
  let tmpText = text;

  tmpText = tmpText.replace("[reference]", reference);

  variables.forEach((element) => {
    let regex = new RegExp(
      `[${element[0].replace(/ /g, "").toLocaleLowerCase()}]`,
      "g"
    );
    tmpText.replace(regex, element[1]);
  });

  return <p>{tmpText}</p>;
}

export default Paragraph;
