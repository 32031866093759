import { useState, useEffect, useRef, ChangeEvent } from "react";
// @ts-ignore
import { RCamera } from "react-camera-media-stream";
import ReactGA from "react-ga4";

import check from "../../images/check.svg";
import trash from "../../images/trash.svg";
import sync from "../../images/sync.svg";
import { getBase64 } from "../../utils/file";

type ButtonPictureMaskProps = {
  title: string;
  value: string | undefined;
  onChange: (base64: string) => void;
  required?: boolean;
  isLabelOptionnal?: boolean;
  mask: string;
};

interface ISomeObject {
  [key: string]: string;
}

function ButtonPictureMask({
  title,
  onChange,
  value,
  required,
  isLabelOptionnal = true,
  mask,
}: ButtonPictureMaskProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [currentImage, setCurrentImage] = useState<string | null>(value || "");
  const [isCamera, setIsCamera] = useState(false);

  const images: ISomeObject = {
    carAR: require("../../images/mask/carAR.png"),
    carAV: require("../../images/mask/carAV.png"),
    carAVG: require("../../images/mask/carAVG.png"),
    carAVD: require("../../images/mask/carAVD.png"),
    carARD: require("../../images/mask/carARD.png"),
    carARG: require("../../images/mask/carARG.png"),
    carLG: require("../../images/mask/carLG.png"),
    carLD: require("../../images/mask/carLD.png"),
  };

  useEffect(() => {
    onChange(currentImage || "");
  }, [currentImage]);

  let currentMask = images.hasOwnProperty(mask) ? images[mask] : "";

  console.log(currentMask);

  return (
    <>
      <div className="button-picture-container">
        {currentImage ? (
          <>
            <div onClick={() => setCurrentImage(null)}>
              <img className="button-picture-icon" alt="trash" src={trash} />
            </div>
            <div className="button-picture-viewer">
              <img src={currentImage} alt={title} />
            </div>
            <div onClick={() => inputRef.current?.click()}>
              <img
                className="button-picture-icon-right"
                alt="sync"
                src={sync}
              />
            </div>
          </>
        ) : null}
        <button
          className="button-document"
          onClick={() => {
            ReactGA.event({
              category: "click_picture",
              action: "open_camera",
              value: 0,
            });
            inputRef.current?.click();
          }}
        >
          <span>
            {title}{" "}
            {required
              ? "(Obligatoire)"
              : isLabelOptionnal
              ? "(Facultatif)"
              : ""}
          </span>
          <img
            alt={mask}
            src={currentImage ? check : currentMask}
            height={24}
          />
        </button>
      </div>
      {/* {isCamera ? (
        <RCamera
          model={currentMask}
          isConfirm={false}
          onTakePicture={(base64: string) => {
            setCurrentImage(base64);
            setIsCamera(false);
          }}
          onClose={() => setIsCamera(false)}
          isFullscreen={true}
          namePicture=""
          isTorch={true}
        />
      ) : null} */}
      <input
        style={{ position: "absolute", top: -1000, left: -1000 }}
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            let file = e.target.files[0];

            getBase64(file)
              .then((result: any) => {
                ReactGA.event({
                  category: "click_picture",
                  action: "close_camera_true",
                  value: 1,
                });
                setCurrentImage(result.base64);
              })
              .catch(() => {
                ReactGA.event({
                  category: "click_picture",
                  action: "close_camera_error",
                  value: 3,
                });
              });
          } else {
            ReactGA.event({
              category: "click_picture",
              action: "close_camera_false",
              value: 2,
            });
          }
        }}
      />
    </>
  );
}

export default ButtonPictureMask;
