import ReactGA from "react-ga4";

import logoMaif from "../../images/maif.png";
import logoLang from "../../images/lang.png";
import logoPluris from "../../images/pluris.png";
import logoAlliance from "../../images/alliance.png";
import cross from "../../images/cross.svg";

type PopupProps = {
  show: boolean;
  status: number;
  onClose: Function;
  texts: Array<string>;
  company?: "MAIF";
  variables: Array<Array<string>>;
  reference: string;
  isTime?: boolean;
};

function Popup({
  show,
  status,
  onClose,
  texts,
  company,
  variables,
  reference,
  isTime,
}: PopupProps) {
  if (!show) {
    return <></>;
  }

  let logo = "";
  if (reference.includes("groupelang.fr")) {
    logo = logoLang;
  } else if (reference.includes("pluris-expertise.com")) {
    logo = logoPluris;
  } else if (reference.includes("alliance-experts.com")) {
    logo = logoAlliance;
  }

  return (
    <div className="popup">
      <div className="popup-close" onClick={() => onClose()}>
        <img alt="close" src={cross} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <img alt="cabinet d'expertise" src={logo} height={40} />
        {company === "MAIF" ? (
          <img alt="MAIF" src={logoMaif} height={40} />
        ) : (
          <></>
        )}
      </div>
      <div className="popup-data">
        {texts.map((text, idx) => {
          let tmpText = text;

          tmpText = tmpText.replace("[reference]", reference);

          variables.forEach((element) => {
            tmpText = tmpText.replace(
              `[${element[0].replace(/ /g, "").toLocaleLowerCase()}]`,
              element[1]
            );
          });

          return <p key={idx}>{tmpText}</p>;
        })}

        <div className="variable-container">
          <p>
            <b>
              <u>Informations du dossier</u>
            </b>
          </p>
          {variables.map((variable, idx) => (
            <p key={idx}>
              {variable[0]} : <b>{variable[1]}</b>
            </p>
          ))}
        </div>
      </div>
      <div style={{ display: "flex", gap: 10 }}>
        <button
          onClick={() => {
            ReactGA.event({
              category: "click_menu",
              action: "start",
              value: 1,
            });
            onClose();
          }}
        >
          Suivant{isTime ? " (durée estimée : 1 minute)" : ""}
        </button>
      </div>
    </div>
  );
}

export default Popup;
