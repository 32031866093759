import { useRef, ChangeEvent, useState, useEffect } from "react";
import ReactGA from "react-ga4";

import { getBase64 } from "../../utils/file";

import check from "../../images/check.svg";
import trash from "../../images/trash.svg";
import camera from "../../images/camera.svg";
import sync from "../../images/sync.svg";
import pdf from "../../images/pdf.png";

type ButtonPictureProps = {
  title: string;
  value: string | undefined;
  onChange: (base64: string) => void;
  required?: boolean;
  isLabelOptionnal?: boolean;
};

function ButtonPicture({
  title,
  onChange,
  value,
  required,
  isLabelOptionnal = true,
}: ButtonPictureProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [currentImage, setCurrentImage] = useState<string | null>(value || "");

  useEffect(() => {
    onChange(currentImage || "");
  }, [currentImage]);

  return (
    <div className="button-picture-container">
      {currentImage ? (
        <>
          <div onClick={() => setCurrentImage(null)}>
            <img className="button-picture-icon" alt="trash" src={trash} />
          </div>
          <div className="button-picture-viewer">
            <img
              height={
                currentImage.includes("image/png") ||
                currentImage.includes("image/jpg") ||
                currentImage.includes("image/jpeg")
                  ? ""
                  : "36"
              }
              src={
                currentImage.includes("image/png") ||
                currentImage.includes("image/jpg") ||
                currentImage.includes("image/jpeg")
                  ? currentImage
                  : pdf
              }
              alt={title}
            />
          </div>
          <div onClick={() => inputRef.current?.click()}>
            <img className="button-picture-icon-right" alt="sync" src={sync} />
          </div>
        </>
      ) : null}
      <button
        className="button-picture"
        onClick={() => {
          ReactGA.event({
            category: "click_picture",
            action: "open_camera",
            value: 0,
          });
          inputRef.current?.click();
        }}
      >
        <span>
          {title}{" "}
          {required ? "(Obligatoire)" : isLabelOptionnal ? "(Facultatif)" : ""}
        </span>
        <img alt="camera" src={currentImage ? check : camera} />
      </button>
      <input
        style={{ position: "absolute", top: -1000, left: -1000 }}
        ref={inputRef}
        type="file"
        accept="image/*,audio/*,.doc,.csv,.pdf"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            let file = e.target.files[0];

            getBase64(file)
              .then((result: any) => {
                ReactGA.event({
                  category: "click_picture",
                  action: "close_camera_true",
                  value: 1,
                });
                setCurrentImage(result.base64);
              })
              .catch(() => {
                ReactGA.event({
                  category: "click_picture",
                  action: "close_camera_error",
                  value: 3,
                });
              });
          } else {
            ReactGA.event({
              category: "click_picture",
              action: "close_camera_false",
              value: 2,
            });
          }
        }}
      />
    </div>
  );
}

export default ButtonPicture;
