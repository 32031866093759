import { useState } from "react";

import imgSerieBlue from "../../images/info/serie-blue.png";

type ButtonInfoProps = {
  title: string;
};

function ButtonInfo({ title }: ButtonInfoProps) {
  const [isPopup, setIsPopup] = useState(false);

  return (
    <>
      <div className="button-picture-container">
        <button className={"button-picture"} onClick={() => setIsPopup(true)}>
          <span>{title}</span>
        </button>
      </div>
      {isPopup ? (
        <div className="popup">
          <div
            style={{
              display: "flex",
              gap: 10,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={imgSerieBlue}
              alt="emplacement numéro de série"
              style={{
                maxWidth: 300,
                maxHeight: "80%",
              }}
            />
            <button
              className={"button-picture"}
              onClick={() => setIsPopup(false)}
            >
              <span>Fermer l'aide</span>
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ButtonInfo;
