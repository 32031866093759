type YesNoProps = {
  text: string;
  checked: boolean | undefined;
  onClick: (checked: boolean) => void;
  required?: boolean;
  defaultFalse?: boolean;
};

function YesNo({ text, checked, onClick, required, defaultFalse }: YesNoProps) {
  return (
    <>
      <p>
        {text}
        {required ? (
          <label className="required"> (réponse obligatoire) *</label>
        ) : null}
      </p>
      <div className="yes-no">
        <span
          className={`${checked === true ? "active" : ""}`}
          onClick={() => onClick(true)}
        >
          Oui
        </span>
        <span
          className={`${
            checked === false || (checked !== true && defaultFalse)
              ? "active"
              : ""
          }`}
          onClick={() => onClick(false)}
        >
          Non
        </span>
      </div>
    </>
  );
}

export default YesNo;
