import { FinalData } from "../types/types";

const getFolder = (folderId: string) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("folderId", folderId);

    fetch("https://app.windtransfer.net/ajax/get-folder.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const openFolder = (folderId: string) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("folderId", folderId);

    fetch("https://app.windtransfer.net/ajax/open-folder.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateFolder = (folderId: string, serverData: Array<FinalData>) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("ID", folderId);
    formData.append("myData", encodeURIComponent(JSON.stringify(serverData)));

    fetch("https://app.windtransfer.net/ajax/upload.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getFolder, openFolder, updateFolder };
