type LinkProps = {
  text: string;
  url: string;
};

function Link({ text, url }: LinkProps) {
  return (
    <p>
      {text}
      <a href={url} target="_blank">
        {url}
      </a>
    </p>
  );
}

export default Link;
